.container {
    position: relative;
}

.page {
    position: absolute;
    left: 0px;
    right: 0px;
}

.page-down-enter {
    opacity: 1;
    transform: translateY(100%);
}

.page-down-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 500ms, transform 500ms;
}

.page-down-exit {
    opacity: 1;
    transform: translateY(0%);
}

.page-down-exit-active {
    opacity: 1;
    transform: translateY(-100%);
    transition: opacity 500ms, transform 500ms;
}
.page-up-enter {
    opacity: 1;
    transform: translateY(-100%);
}

.page-up-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 500ms, transform 500ms;
}

.page-up-exit {
    opacity: 1;
    transform: translateY(0%);
}

.page-up-exit-active {
    opacity: 1;
    transform: translateY(100%);
    transition: opacity 500ms, transform 500ms;
}